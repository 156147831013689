import { register } from '@netcentric/component-loader';
import { isOnDesktop } from '../../../commons/utils';
import config from './batcom-linklist.config';

class Linklist {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.init();
  }

  init() {
    if(this.el.classList.contains(this.classes.batcomSideNav)){
      this.setRefs();
      this.toggleSideNavigation();
    }
  }

  setRefs() {
    this.linkListTitle = this.el.querySelector(this.selectors.linkListTitle);
    this.linkListItemsWrapper = this.el.querySelector(this.selectors.linkListWrapper);
    this.linkListWrapperVisible = this.classes.linkListWrapperVisible;
    this.columnControlColumn = document.querySelectorAll(this.selectors.columnControlColumn);
    this.sideNavigation = document.querySelector(this.selectors.sideNavigation);
  }

  toggleSideNavigation() {
    let wasNotDesktop = !isOnDesktop();
    let toggleVisibility = null;
    
    const attachEventListener = () => {
      if (!this.linkListTitle || toggleVisibility) return;
      toggleVisibility = () => {
        this.linkListItemsWrapper.classList.toggle(this.linkListWrapperVisible);
      };
      this.linkListTitle.addEventListener('click', toggleVisibility);
    };

    const detachEventListener = () => {
      if (!this.linkListTitle || !toggleVisibility) return;
      this.linkListTitle.removeEventListener('click', toggleVisibility);
      toggleVisibility = null;
    };

    if (wasNotDesktop) {
      attachEventListener();
    }

    const resizeObserver = new ResizeObserver(() => {
      const currentlyNotDesktop = !isOnDesktop();
      if (currentlyNotDesktop && !wasNotDesktop) {
        attachEventListener();
      } else if (!currentlyNotDesktop && wasNotDesktop) {
        detachEventListener();
      }
      wasNotDesktop = currentlyNotDesktop;
    });

    resizeObserver.observe(document.body);
  }
}

const linkList = new Linklist(document.querySelector('.batcom-linklist'));
register({ linkList });
