export default {
  selectors: {
    linkListTitle: '.batcom-linklist__side-navigation .batcom-linklist__title',
    linkListWrapper: '.batcom-linklist__side-navigation .batcom-linklist__wrapper',
    columnControlColumn: '.columncontrol__wrapper > .columncontrol__column',
    sideNavigation: '.batcom-linklist__side-navigation',
    sideNavigationWrapper : '.batcom-linklist__side-navigation>.batcom-linklist__wrapper',
  },
  classes: {
    batcomSideNav: 'batcom-linklist__side-navigation',
    linkListWrapperVisible: 'batcom-linklist__wrapper--visible',
  },
};
